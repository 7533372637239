<template>
  <div>
    <Search
      ref="searchStr"
      :selectStr="goodsName"
      class="search-position"
      @setSelectStr="setSelectStr"
    >
      <div slot="left" class="search-left">
        <van-icon
          name="arrow-left"
          size="24"
          color="#6F6F6F"
          @click="$router.go(-1)"
        />
      </div>
      <div slot="right" class="search-right">
        <van-button
          round
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="onSearch"
        >
          搜索
        </van-button>
      </div>
    </Search>
    <div class="goods-class-layout">
      <ul class="title-layout">
        <li
          class="title-text"
          :class="{ highlight: orderBy === 1 }"
          @click="setSelect(1)"
        >
          <div class="text">新品</div>
        </li>
        <li
          class="title-text"
          :class="{ highlight: orderBy === 2 }"
          @click="setSelect(2)"
        >
          <div class="text">销量</div>
        </li>
        <li
          class="title-text"
          :class="{ highlight: orderBy === 3 }"
          @click="setSelect(3)"
        >
          <div class="text">评论</div>
        </li>
        <li
          class="title-text"
          :class="{ highlight: orderBy >= 4 }"
          @click="setSelect(4)"
        >
          <div class="text">价格</div>
          <img
            class="sequence"
            v-if="orderBy < 4"
            src="~@/assets/common/hit_refresh_button@3x.png"
          />
          <img
            class="sequence"
            v-else-if="orderBy == 4"
            src="~@/assets/common/refresh_button_on_red@3x.png"
          />
          <img
            class="sequence"
            v-else
            src="~@/assets/common/refresh_button_in_red@3x.png"
          />
        </li>
      </ul>
      <GoodsShowLable
        ref="goodList"
        :tagId="0"
        :sendType="2"
        :classId="classId"
        :goodName="goodsName"
        :orderBy="orderBy"
      ></GoodsShowLable>
    </div>
    <!-- <div>
      <SearchPage v-if="showSearch" @setSearchStr="setSearchStr"></SearchPage>
    </div> -->
  </div>
</template>

<script>
import Search from "@/components/search/search.vue";
import GoodsShowLable from "@/components/goods-show/goods-show-label.vue";
// import SearchPage from "@/pages/goods/goods-list/moudles/search-page";
export default {
  name: "shop-details",
  components: {
    Search,
    GoodsShowLable,
    // SearchPage,
  },
  data() {
    return {
      orderBy: 1,
      goodsName:
        this.$route.query.strs === undefined ? "" : this.$route.query.strs,
      // goodsStr: this.$route.query.strs,
      classId: this.$route.query.classId * 1 || 0,
      goodsName1: "",
    };
  },
  mounted() {
    // this.setSearchStr(this.goodsName);
  },
  methods: {
    setSelectStr(value) {
      this.goodsName1 = value;
    },
    onSearch() {
      this.goodsName = this.goodsName1;
    },
    setSearchStr(searchStr) {
      this.goodsName = searchStr;
      this.$refs.goodList.searchStr = searchStr;
      this.$refs.searchStr.value = searchStr;
    },
    setSelect(index) {
      if (index === this.orderBy && index !== 4) {
        return false;
      }
      if (index === 4) {
        this.orderBy = this.orderBy === 4 ? 5 : 4;
      } else {
        this.orderBy = index;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-position {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8000;
}
.goods-class-layout {
  padding: 58px 0px 0 0px;
  .title-layout {
    display: flex;
    justify-content: space-around;
    padding: 12px 0;
    .title-text {
      display: flex;
      align-items: center;
      color: #6f6f6f;
      font-size: 18px;
      .sequence {
        width: 10px;
        height: 16px;
        margin-left: 4px;
      }
    }
    .highlight {
      font-weight: 500;
      font-size: 20px;
      color: #ed301d;
    }
  }
}
.text {
  display: flex;
  align-items: center;
  min-width: 40px;
  height: 30px;
}
</style>
